@import 'scss/variables.scss';

.react-table {
  font-size: 12px;

  thead {
    box-shadow: 0 4px 15px -2px rgba(0, 0, 0, 0.15);
  }

  .current-month {
    background-color: $gray-80;
  }

  &.table-fixed {
    position: sticky;
    left: 0;
    z-index: 1;
    background-color: #fff;

    tr:first-child th {
      background-color: #a1cd64;
      color: #fff;
      text-align: center;
      font-size: 16px;
    }
  }

  th {
    font-weight: 500;
  }

  td {
    font-size: 12px;
  }

  button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    outline: inherit;
  }

  td {
    white-space: nowrap;
  }

  .group-quantity {
    color: $blue-100;
  }

  .active {
    background-color: $gray-80;
    border-bottom: 1px solid $gray-80;
  }
}
