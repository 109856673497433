.circular-progressbar {
  height: calc(var(--radio) * 2px);
  width: calc(var(--radio) * 2px);
  position: relative;

  svg {
    height: 100%;
    width: 100%;
    transform: rotate(90deg);
  }

  circle {
    fill: none;
    stroke: #d0daea;
    stroke-width: calc(var(--stroke) * 1px);
    stroke-dasharray: var(--perimeter);
    stroke-dashoffset: 0;
    stroke-linecap: round;
  }

  circle.progressbar {
    stroke: gray;
    stroke-dashoffset: calc(var(--perimeter) - (var(--perimeter) * var(--percent)) / 100);
    animation: anim 0.8s linear alternate forwards;
  }

  circle.green {
    stroke: #83bb35;
  }

  circle.red {
    stroke: #e0252e;
  }

  circle.orange {
    stroke: #f2b01a;
  }

  .content {
    position: absolute;
    top: calc(var(--radio) * 0.35px);
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    border-radius: 50%;
    width: calc(var(--radio) * 1.3px);
    height: calc(var(--radio) * 1.3px);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: calc(var(--radio) * 0.35px);
    font-weight: bold;
  }
}

@keyframes anim {
  0% {
    stroke-dashoffset: var(--perimeter);
  }
}
