@import '../../scss/variables';

#budgets {
  .budget-list {
    .budget {
      min-width: 15rem;
      max-width: 300px;
      border-radius: 10px;
      border: 4px solid $gray-80;
      padding: $spacing-large;
      background-color: $white;
      position: relative;

      .budget-info {
        .divisions {
          width: 40%;
        }
      }

      .divisions {
        display: flex;
        justify-content: space-between;
        padding: $spacing-large $spacing-regular;
        font-size: $font-size-medium;

        .header {
          font-weight: $font-weight-bolder;
        }

        .details {
          font-weight: $font-weight-normal;
        }
      }

      .usage-in-chart {
        color: $black-85;
        font-weight: $font-weight-bold;
        font-size: $font-size-large;
        text-align: center;
      }

      &.notInitiated,
      &.pendingUpdate {
        border-color: $green !important;
      }
      &.pending,
      &.requireReschedule {
        border-color: $yellow !important;
      }
      &.rejected,
      &.rescheduleRejected {
        border-color: $red !important;
      }
      &.approved {
        border-color: $blue-100 !important;
      }

      .status-label {
        position: absolute;
        font-size: 10px;
        padding: 2px 10px;
        color: #fff;
        border-radius: 5px;
        top: 10px;
        left: 24px;

        &.notInitiated,
        &.pendingUpdate {
          background-color: $green !important;
        }
        &.pending,
        &.requireReschedule {
          background-color: $yellow !important;
          color: #333;
        }
        &.rejected,
        &.rescheduleRejected {
          background-color: $red !important;
        }
        &.approved {
          background-color: $blue-100 !important;
        }
      }

      .card-body {
        background-color: white;

        .card-text {
          background-color: white;
        }
      }
    }
  }

  .budget-entry {
    .budget-input-card {
      border: 1px solid $gray-80;
      border-radius: 10px;

      .title {
        color: #6b788c;
        font-weight: $font-weight-bold;
        font-size: $font-size-large;

        .calender-days,
        .working-days {
          border-radius: $spacing-large;
          font-size: $font-size-regular;
          font-weight: $font-weight-normal;
          color: $black-2;

          span {
            padding-left: $spacing-small;
            font-size: $font-size-regular;
            font-weight: $font-weight-bolder;
          }
        }

        .calender-days {
          margin-right: $spacing-small;
          background-color: $gray-80 !important;
        }

        .working-days {
          background-color: $orange-30 !important;
        }
      }

      .input-box {
        color: $blue-100;
        font-weight: $font-weight-bolder;
        font-size: $font-size-xxlarge;
        border: none;
        border-bottom: 1px solid #adadad;
        border-radius: unset;
        padding: 0 10px;

        &:focus {
          box-shadow: none;
        }
      }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      display: none;
    }
    button {
      width: 100% !important;
    }
  }

  .btn-app {
    background-color: $blue-100;
    color: #fff;
    font-size: $font-size-large;
    border-radius: 20px !important;
    padding: 6px 30px;
  }
}

.slick-align-left {
  .slick-track {
    margin: 0;
  }
}
