@import 'scss/variables.scss';

.variance-row-content-template {
  margin-top: 10px;
}

.variance-row-content {
  margin-top: 28px;
}

.variance-row-content-template,
.variance-row-content {
  min-width: 140px;
  h6 {
    font-size: 12px;
  }
}

.variance-row-content :not(.text-danger, .text-success) .y2d {
  color: #aaa;
}

.y2d {
  filter: opacity(65%);
}

table {
  background-color: #fff;

  &.table-fixed {
    position: sticky;
    left: 0;
    z-index: 1;
    background-color: #fff;
    width: auto;
    box-shadow: 0 4px 15px -2px rgba(0, 0, 0, 0.15);
  }

  .current-month {
    background-color: #d0daea52 !important;
  }

  th {
    font-weight: 500;
  }

  th,
  td {
    white-space: nowrap;
  }

  th {
    font-weight: 500;
  }

  td {
    font-size: 12px;
  }

  button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    outline: inherit;
  }
}
