@import '../../scss/variables';

.top-nav {
  background-color: $background-white;
}

.display {
  display: flex;
  flex-direction: column;
}

.name {
  text-align: end;
}

.info-avatar {
  display: flex;
  flex-direction: column;
  align-items: end;

  span:first-child {
    color: #000;
  }

  span:last-child {
    font-size: 12px;
  }
}

.nav-menu {
  .dropdown-toggle::after {
    display: none !important;
  }

  .dropdown-item {
    font-size: 14px;
    font-weight: 300;
    color: #878787;
  }

  .dropdown-menu {
    top: 100%;
    right: -40px;
    left: auto;
    margin-top: 0.125rem;
  }

  .dropdown-item:hover,
  .dropdown-item:focus {
    color: #61dafb;
    background-color: #f5f5f5;
  }
}
