@import 'scss/variables.scss';

.portafolios {
  .portfolio-header {
    font-size: $font-size-large;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  .portfolio-card {
    max-width: 400px;
    display: inline-block;
    min-width: 350px;
    min-height: 450px;

    &.portfolio-card-highlighted {
      border: 4px solid $yellow;
    }
  }

  .card {
    h2 {
      font-size: 28px;
    }

    h3 {
      font-size: 20px;
    }
  }
}
