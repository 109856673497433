@import '../scss/variables';

.main {
  .tab-content {
    background-color: $background-white;
    padding-top: $spacing-xl;
    border-radius: 10px 0 10px 10px;
  }

  .page-title {
    color: $green;
    font-weight: $font-weight-bolder;
    font-size: $font-size-lg;
  }
}
