@import 'scss/variables.scss';

.card {
  border: 1px solid #d0daea;
  border-radius: 10px;
  h2 {
    font-size: $font-size-xxxlarge;
    font-weight: $font-weight-bold;
    white-space: nowrap;
  }

  h3 {
    font-size: $font-size-xlarge;
    font-weight: $font-weight-bold;
    white-space: nowrap;
  }

  h3:last-child {
    margin: 0;
  }

  h4 {
    font-size: $font-size-xlarge;
    font-weight: $font-weight-bold;
    white-space: nowrap;
  }

  h5 {
    font-size: $font-size-large;
    font-weight: $font-weight-bold;
    white-space: nowrap;
  }
}

.gauge-summary-card {
  min-height: 300px;

  .card-color {
    min-width: 220px;
    flex-shrink: 0;
    padding: 20px;
  }
}

.card-with-shadow {
  border: none;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.22);
}

.card-title {
  font-weight: $font-weight-bolder;
}

.card-subtitle {
  font-size: $font-size-medium;
  font-weight: 300;
  letter-spacing: -0.02em;
  color: #6b788c;
}

.card-progressbar {
  width: 100%;
  text-align: center;

  .semi-circular-progressbar-wrapper {
    margin: 0 auto;
  }
}

.card-color {
  color: #fff;
  border: none;
  border-radius: 10px;
  padding: 5px;
  .card-subtitle {
    color: #fff;
  }
}

.card-green {
  background-color: #83bb35;
}

.card-yellow {
  background-color: #f8c740;
}

.card-orange {
  background-color: #f2b01a;
}

.card-red {
  background-color: #e0252e;
}

.card-outline-green {
  border: 2px solid #83bb35;
}

.card-outline-orange {
  border: 2px solid #f2b01a;
}

.card-outline-red {
  border: 2px solid #e0252e;
}
