@import '../../scss/variables';

.menu-bar {
  padding-bottom: 0;
  display: flex;
  justify-content: space-between;

  .menu-title {
    font-weight: $font-weight-bolder;
    color: $blue-100;

    .dropdown {
      display: inline-block;
      padding-left: $spacing-regular;
    }
  }

  .menu-items {
    display: flex;
    .nav-item {
      & a {
        text-decoration: none;
        color: $secondary-color;

        .active {
          color: $primary-color;
          font-weight: $font-weight-bold;
        }

        .card {
          border-radius: $spacing-small $spacing-small 0 0;
          margin-right: $spacing-small;
          border-style: none;
          border-bottom: 2px solid $background-blue;

          &.active {
            border-bottom: none;

            .card-body {
              padding-bottom: 18px;
            }
          }
        }
      }
      &:last-child {
        .card {
          margin-right: 0px;
        }
      }
    }
  }

  //.menu-card {
  //  min-height: 150px;
  //  .card-body {
  //    display: flex;
  //    justify-content: center;
  //    align-items: center;
  //  }
  //  &.active {
  //    background-color: #6c757d;
  //    font-weight: bold;
  //    color: #f9fafb;
  //  }
  //}
}
