@import 'scss/fonts.scss';
@import 'scss/variables';
@import 'scss/overrides';

@import '~slick-carousel/slick/slick';
@import '~slick-carousel/slick/slick-theme';

body {
  font-family: 'Poppins';
}

.App {
  font-weight: $font-weight-normal;
  background-color: $background-blue;
  padding-bottom: 2.5rem; /* Footer height */
}

.alert-info {
  color: #6283ba;
  background-color: #e3eeff;
  border-color: #6283ba;
}

.alert-danger {
  color: $red;
  background-color: #fff9f9;
  border-color: $red;
}

.slick-slide > div {
  margin: 0 10px;
}
.slick-list {
  margin: 0 -10px;
}

.slick-prev,
.slick-next {
  width: 0;
  height: 0;
  top: 40%;
  z-index: 1;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
}

.slick-prev {
  border-right: 25px solid #6283ba;
  left: -12px;
}

.slick-prev.slick-disabled,
.slick-next.slick-disabled {
  border: none;
}

.slick-next {
  border-left: 25px solid #6283ba;
  right: -12px;
}

.slick-prev::before,
.slick-next::before {
  content: none;
}

.section-gray {
  background-color: $blue-10;
  border: none;
  border-radius: 10px;
  margin-top: 20px;
}
