.space-section {
  background-color: #eef2f7;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 20px;

  .table-accounts-space {
    th:last-child {
      max-width: 33%;
    }
  }

  input,
  select {
    font-size: 13px;
  }

  h1 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 16px;
  }

  table {
    td {
      vertical-align: middle;

      .action-buttons {
        text-align: center;
        button {
          font-size: 12px;
          outline: 0;
          border: 0;

          &:focus {
            box-shadow: none;
          }
        }
      }
    }
  }

  .table-accounts-space td,
  .aws-accounts-estimate-table td {
    background-color: white;
  }

  .aws-accounts-estimate-table {
    input {
      width: 100%;
      padding: 6px 8px;
      border: 2px solid #eee;
      font-size: 12px;
      outline: 0;
    }

    input:focus,
    input:focus-visible {
      border: 2px solid #6283ba;
    }
  }

  .estimate-options {
    text-align: center;
    font-size: 18px;

    .plus-icon {
      margin-right: 10px;

      svg {
        fill: white;
      }
    }
  }
}

.pagination-controls {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
  font-size: 12px;
  button {
    background-color: #fff;
    height: 35px;
    width: 35px;
  }
}

.space-section-readonly {
  table {
    th,
    td {
      white-space: break-spaces;
      max-width: 250px;
    }
  }
}
